

/*=====================================
        verification Start Here
=====================================*/

.verification-main{
    margin: 128px 0 72px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0 72px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 580px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                        margin: 24px 0 40px 0;
                    }
                    .action{
                        display: flex;
                    }
                }
            }
        }
    }
}

/*=====================================
        verification End Here
=====================================*/

/*=====================================
        verification need Start Here
=====================================*/

.verification-need{
    margin: 128px 0 72px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0 72px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 580px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Secondary-color;
                    }
                    .accordion{
                        margin: 24px 0 40px 0;
                        .accordion-item{
                            background-color: transparent;
                            border: none;
                            border-radius: 0;
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                            .accordion-header{
                                .accordion-button{
                                    color: $Secondary-color;
                                    background-color: transparent;
                                    border-bottom: 1px solid $Secondary-color10;
                                    border-radius: 0;
                                    padding: 0 0 16px 0;
                                    box-shadow: none;
                                    &::after{
                                        width: 24px;
                                        height: 24px;
                                        background-image: url("/assets/img/plus-icon.svg");
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }
                                }
                                .accordion-button:not(.collapsed){
                                    color: $Primary-color;
                                    &::after{
                                        background-image: url("/assets/img/minus-icon.svg");
                                        transform: rotate(360deg);
                                    }
                                }
                            }
                            .accordion-collapse{
                                .accordion-body{
                                    color: $Secondary-color75;
                                    padding: 16px 28px;
                                    .linkk{
                                        display: inline;
                                        color: $Secondary-color;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .action{
                        display: flex;
                    }
                }
            }
        }
    }
}

/*=====================================
        verification need End Here
=====================================*/