


/*=====================================
        Facebook Start Here
=====================================*/

.facebook-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            gap: 24px 0;
            justify-content: center;
            .col-box-otr{
                .col-box-inr{
                    background-color: $White-color;
                    border-radius: 16px;
                    text-align: center;
                    box-shadow: $Box-shadow;
                    height: 100%;
                    .heading-otr{
                        padding: 24px 24px 20px 24px;
                        border-bottom: 1px solid $Secondary-color10;
                        .heading{
                            color: $Secondary-color;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                    }
                    .content{
                        padding: 24px 28px 28px 28px;
                        .price-otr{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $Secondary-color;
                            margin-bottom: 16px;
                            .price-inr{
                                color: $Secondary-color75;
                                margin-left: 12px;
                                text-decoration: line-through;
                            }
                        }
                        .action{
                            display: flex;
                            .cart-btn{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Facebook End Here
=====================================*/

/*=====================================
        Content Start Here
=====================================*/

.content-main{
    margin: 128px 0 72px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0 72px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 700px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        margin-bottom: 16px;
                        color: $Secondary-color;
                    }
                    .desc-ul{
                        .desc-li{
                            color: $Secondary-color75;
                            margin-bottom: 16px;
                        }
                    }
                    .desc{
                        color: $Secondary-color75;
                        &:not(:last-child){
                            margin-bottom: 16px;
                        }
                    }
                    .action{
                        display: flex;
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

/*=====================================
        Content End Here
=====================================*/