/*=====================================
        Color Variable's
=====================================*/

$Primary-color: #0666F6;
$Primary-color2: #ED7171;
$Secondary-color: #151242;
$Secondary-color75: rgba(21, 18, 66, 0.75);
$Secondary-color65: rgba(21, 18, 66, 0.65);
$Secondary-color50: rgba(21, 18, 66, 0.50);
$Secondary-color15: rgba(21, 18, 66, 0.15);
$Secondary-color10: rgba(21, 18, 66, 0.10);
$White-color: #ffffff;
$White-color75: rgba(255, 255, 255, 0.75);
$Gradient: linear-gradient(111.04deg, rgba(6, 102, 246, 0.25) 0%, rgba(255, 255, 255, 0) 100%);

/*=====================================
        Fonts Variable's
=====================================*/

$Font-size-h1: 72px;
$Font-size-h2: 48px;
$Font-size-h3: 24px;
$Font-size-p1: 20px;
$Font-size-p2: 18px;
$Font-size-p3: 16px;

/*=====================================
        Line-height Variable's
=====================================*/

$line-height-h1: 92px;
$line-height-h2: 64px;
$line-height-h3: 36px;
$line-height-p1: 36px;
$line-height-p2: 34px;
$line-height-p3: 32px;

/*=====================================
        Font-family Variable's
=====================================*/

$font-family-semibold: "Poppins-SemiBold";
$font-family-regular: "Poppins-Regular";

/*=====================================
        Box Shadow Variable's
=====================================*/


$Box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);