

/*=====================================
        Domain Register Start Here
=====================================*/

.navbar-domain-register{
    position: relative;
    background-image: url("/assets/img/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $Gradient;
        z-index: -1;
    }
    .navbar-main{
        padding: 24px 0;
        border-bottom: 1px solid $Secondary-color15;
        .container-fluid{
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo-otr{
                    .logo{
                        width: 120px;
                        height: 80px;
                        object-fit: contain;
                    }
                }
                .menu-otr{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .menu-ul{
                        display: flex;
                        align-items: center;
                        gap: 0 50px;
                        .menu-li{
                            position: relative;
                            transition: .3s;
                            &:hover{
                                .drop-otr{
                                    top: 24px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            .menu-a{
                                color: $Secondary-color75;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .drop-otr{
                                position: absolute;
                                top: 40px;
                                left: -24px;
                                padding-top: 42px;
                                z-index: 9999;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                .drop-ul{
                                    width: 270px;
                                    background-color: $White-color;
                                    box-shadow: $Box-shadow;
                                    border-radius: 16px;
                                    .drop-li{
                                        position: relative;
                                        padding: 10px 28px;
                                        &:hover{
                                            .inr-drop-otr{
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                        &:not(:last-child){
                                            border-bottom: 1px solid $Secondary-color10;
                                        }
                                        .drop-a{
                                            display: inline;
                                            color: $Secondary-color;
                                            cursor: pointer;
                                            transition: .3s;
                                            &:hover{
                                                color: $Primary-color;
                                            }
                                        }
                                        .inr-drop-otr{
                                            position: absolute;
                                            top: 0;
                                            right: -210px;
                                            padding-top: 0px;
                                            z-index: 9999;
                                            opacity: 0;
                                            visibility: hidden;
                                            transition: 0.3s;
                                            .inr-drop-ul{
                                                width: 250px;
                                                background-color: $White-color;
                                                box-shadow: $Box-shadow;
                                                border-radius: 16px;
                                                .inr-drop-li{
                                                    padding: 10px 28px;
                                                    &:not(:last-child){
                                                        border-bottom: 1px solid $Secondary-color10;
                                                    }
                                                    .inr-drop-a{
                                                        display: inline;
                                                        color: $Secondary-color;
                                                        transition: .3s;
                                                        &:hover{
                                                            color: $Primary-color;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .action{
                    display: flex;
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .contact-btn{
                        display: flex;
                        align-items: center;
                        transition: .3s;
                        &:hover{
                            .icon{
                                color: $White-color;
                            }
                        }
                        .icon{
                            font-size: 24px;
                            color: $Primary-color;
                            margin-left: 12px;
                            transition: .3s;
                        }
                    }
                }
                .burger-icon-otr{
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background-color: $Secondary-color10;
                    transition: .3s;
                    @include breakpoint($secreen-max-md){
                        display: flex;
                    }
                    &:hover{
                        background-color: $Secondary-color;
                        .icon{
                            color: $White-color;
                        }
                    }
                    .icon{
                        font-size: 24px;
                        color: $Secondary-color;
                        transition: .3s;
                    }
                }
            }
        }
    }
    .domain-register{
        padding: 72px 0 96px 0;
        .container{
            .wrapper{
                text-align: center;
                .heading{
                    color: $Secondary-color;
                }
                .desc1{
                    color: $Secondary-color;
                    margin: 24px 0;
                }
                .desc{
                    color: $Secondary-color75;
                }
                .search-otr{
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    margin: 48px 0;
                    .input-otr{
                        width: 100%;
                        .input{
                            flex: 1;
                            background-color: $White-color;
                        }
                    }
                    .action{
                        .search-btn{
                            border: none;
                            outline: none;
                        }
                    }
                }
                .domain-img-main{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 24px;
                    .img-otr{
                        display: flex;
                        align-items: center;
                        padding: 24px;
                        background-color: $White-color;
                        border-radius: 16px;
                        box-shadow: $Box-shadow;
                        .img{
                            width: 100px;
                            height: 60px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Domain Register End Here
=====================================*/

/*=====================================
        dns Start Here
=====================================*/

.dns-main{
    margin: 96px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 350px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 350px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 300px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .tag{
                        &::before{
                            display: none;
                        }
                    }
                    .heading{
                        margin: 8px 0 24px 0;
                        color: $Secondary-color;
                    }
                    .desc1{
                        color: $Secondary-color;
                    }
                    .desc2,
                    .desc3{
                        color: $Secondary-color75;
                    }
                    .desc{
                        &:not(:last-child){
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        dns End Here
=====================================*/

/*=====================================
        Payment Start Here
=====================================*/

.payment-section{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 72px 0 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 320px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 350px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 250px;
                        }
                        @include breakpoint($secreen-xxs){
                            height: 220px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        margin-bottom: 24px;
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                    }
                }
            }
        }
    }
}

/*=====================================
        Payment End Here
=====================================*/