


/*=====================================
    our-hosting-plans Start Here
=====================================*/

.our-hosting-plans{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 72px 0 96px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            .tag{

            }
            .heading{
                margin: 8px 0 24px 0;
                color: $Secondary-color;
            }
            .desc{
                color: $Secondary-color75;
            }
        }
        .tabs-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 48px 0 72px 0;
            @include breakpoint($secreen-xs){
                margin: 36px 0 48px 0;
            }
            .nav-tabs{
                justify-content: center;
                border: none;
                gap: 16px;
                .nav-item{
                    .nav-link{
                        padding: 4px 16px;
                        border-radius: 8px;
                        background-color: $Secondary-color10;
                        color: $Primary-color;
                        border: none;
                        &:hover{
                            background-color: $Secondary-color;
                            color: $White-color;
                        }
                    }
                    .active{
                        background-color: $Secondary-color;
                        color: $White-color;
                    }
                }
            }
        }
        .tab-content{
            .row-custom{
                gap: 24px 0;
                .col-price-otr{
                    .col-price-inr{
                        background-color: $White-color;
                        border-radius: 16px;
                        overflow: hidden;
                        box-shadow: $Box-shadow;
                        .name-plan{
                            text-align: center;
                            color: $Secondary-color;
                            padding: 24px 0;
                        }
                        .icon-main{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            background-color: $Secondary-color10;
                            padding: 28px 24px 20px 24px;
                            .icon{
                                margin-bottom: 16px;
                            }
                            .price{
                                color: $Secondary-color;
                                display: flex;
                                align-items: center;
                                .price-inr{
                                    color: $Secondary-color65;
                                }
                            }
                        }
                        .plan-ul{
                            padding: 28px 36px;
                            @include breakpoint($secreen-xxs){
                                padding: 28px 24px;
                            }
                            .plan-li{
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                &:not(:last-child){
                                    margin-bottom: 16px;
                                }
                                .text-check{
                                    flex: 1;
                                    color: $Secondary-color75;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                                .title{
                                    color: $Primary-color;
                                    text-decoration: underline;
                                }
                            }
                        }
                        .action{
                            display: flex;
                            padding: 0 36px 32px 36px;
                            .choose-btn{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    our-hosting-plans End Here
=====================================*/

/*=====================================
        Faq Start Here
=====================================*/

.faq-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 72px 0 96px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .tag{
                margin-bottom: 8px;
            }
            .heading{
                color: $Secondary-color;
            }
        }
        .row-custom{
            justify-content: center;
            .col-faq-otr{
                .col-faq-inr{
                    .accordion{
                        .accordion-item{
                            background-color: transparent;
                            border: none;
                            border-radius: 0;
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                            .accordion-header{
                                .accordion-button{
                                    color: $Secondary-color;
                                    background-color: transparent;
                                    border-bottom: 1px solid $Secondary-color10;
                                    border-radius: 0;
                                    padding: 0 0 16px 0;
                                    box-shadow: none;
                                    &::after{
                                        width: 24px;
                                        height: 24px;
                                        background-image: url("/assets/img/plus-icon.svg");
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }
                                }
                                .accordion-button:not(.collapsed){
                                    color: $Primary-color;
                                    &::after{
                                        background-image: url("/assets/img/minus-icon.svg");
                                        transform: rotate(360deg);
                                    }
                                }
                            }
                            .accordion-collapse{
                                .accordion-body{
                                    color: $Secondary-color75;
                                    padding: 16px 28px;
                                    .linkk{
                                        display: inline;
                                        color: $Secondary-color;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Faq End Here
=====================================*/

/*=====================================
        cpanel Start Here
=====================================*/

.cpanel-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 320px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 350px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 250px;
                        }
                        @include breakpoint($secreen-xxs){
                            height: 220px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .tag{
                        &::before{
                            display: none;
                        }
                    }
                    .heading{
                        margin: 8px 0 24px 0;
                        color: $Secondary-color;
                    }
                    .desc1{
                        color: $Secondary-color;
                    }
                    .desc2,
                    .desc3{
                        color: $Secondary-color75;
                    }
                    .desc{
                        &:not(:last-child){
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        cpanel End Here
=====================================*/

/*=====================================
        hosting-provider Start Here
=====================================*/

.hosting-provider-main{
    background-image: url("/assets/img/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 128px 0;
    @include breakpoint($secreen-xs){
        padding: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 280px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 350px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 250px;
                        }
                        @include breakpoint($secreen-xxs){
                            height: 220px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        margin-bottom: 24px;
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                    }
                }
            }
        }
    }
}

/*=====================================
        hosting-provider End Here
=====================================*/

/*=====================================
        Relay-service Start Here
=====================================*/

.Relay-service-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 650px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        margin: 8px 0 24px 0;
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                        margin-bottom: 40px;
                    }
                    .row-custom-inr{
                        gap: 24px 0;
                        .col-box-otr{
                            .col-box-inr{
                                background-color: $White-color;
                                padding: 36px 36px 28px 36px;
                                box-shadow: $Box-shadow;
                                border-radius: 16px;
                                height: 100%;
                                @include breakpoint($secreen-lg){
                                    padding: 24px 24px 20px 24px;
                                }
                                @include breakpoint($secreen-max-sm){
                                    padding: 24px 24px 20px 24px;
                                }
                                .icon-heading{
                                    display: flex;
                                    align-items: center;
                                    gap: 0 30px;
                                    margin-bottom: 24px;
                                    @include breakpoint($secreen-lg){
                                        flex-direction: column;
                                        align-items: flex-start;
                                        gap: 16px 0;
                                        margin-bottom: 16px;
                                    }
                                    @include breakpoint($secreen-max-sm){
                                        margin-bottom: 16px;
                                        gap: 0 16px;
                                    }
                                    @include breakpoint($secreen-xxs){
                                        flex-direction: column;
                                        align-items: flex-start;
                                        gap: 16px 0;
                                        margin-bottom: 16px;
                                    }
                                    .icon-otr{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 72px;
                                        height: 72px;
                                        background-color: $Secondary-color;
                                        border-radius: 16px;
                                    }
                                    .icon-otr2,
                                    .icon-otr5{
                                        background-color: $Primary-color;
                                    }
                                    .icon-otr3,
                                    .icon-otr4{
                                        background-color: $Primary-color2;
                                    }
                                    .box-heading{
                                        flex: 1;
                                        color: $Secondary-color;
                                    }
                                }
                                .box-desc{
                                    color: $Secondary-color65;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Relay-service End Here
=====================================*/

/*=====================================
        Client Logo Start Here
=====================================*/

.client-logo-section{
    padding: 96px 0;
    position: relative;
    background-image: url("/assets/img/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $Gradient;
        z-index: -1;
    }
    @include breakpoint($secreen-xs){
        padding: 48px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            gap: 72px 0;
            @include breakpoint($secreen-xs){
                gap: 48px 0;
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                        margin: 24px 0 40px 0;
                    }
                    .action{
                        display: flex;
                    }
                }
            }
            .col-logo-otr{
                .col-logo-inr{
                    gap: 24px 0;
                    .logo-otr{
                        @include breakpoint($secreen-xs){
                            width: 50%;
                        }
                        .logo-inr{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 150px;
                            background-color: $White-color;
                            box-shadow: $Box-shadow;
                            border-radius: 16px;
                            padding: 24px;
                            @include breakpoint($secreen-xxs){
                                height: 120px;
                            }
                            .logo{
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Client Logo End Here
=====================================*/

/*=====================================
    hosting-provider2 Start Here
=====================================*/

.hosting-provider2{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xs){
                gap: 48px 0;
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Secondary-color;
                    }
                    .desc1{
                        margin: 16px 0;
                    }
                    .desc{
                        color: $Secondary-color75;
                    }
                    .img{
                        margin-top: 48px;
                        width: 100%;
                        height: 550px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-boxes-otr{
                .col-boxes-inr{
                    gap: 24px 0;
                    .col-box-otr{
                        .col-box-inr{
                            background-color: $White-color;
                            padding: 36px 36px 28px 36px;
                            box-shadow: $Box-shadow;
                            border-radius: 16px;
                            height: 100%;
                            @include breakpoint($secreen-lg){
                                padding: 24px 24px 20px 24px;
                            }
                            @include breakpoint($secreen-max-sm){
                                padding: 24px 24px 20px 24px;
                            }
                            .icon-heading{
                                display: flex;
                                align-items: center;
                                gap: 0 30px;
                                margin-bottom: 24px;
                                @include breakpoint($secreen-lg){
                                    flex-direction: column;
                                    align-items: flex-start;
                                    gap: 16px 0;
                                    margin-bottom: 16px;
                                }
                                @include breakpoint($secreen-max-sm){
                                    margin-bottom: 16px;
                                    gap: 0 16px;
                                }
                                @include breakpoint($secreen-xxs){
                                    flex-direction: column;
                                    align-items: flex-start;
                                    gap: 16px 0;
                                    margin-bottom: 16px;
                                }
                                .icon-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 72px;
                                    height: 72px;
                                    background-color: $Secondary-color;
                                    border-radius: 16px;
                                }
                                .icon-otr2,
                                .icon-otr5{
                                    background-color: $Primary-color;
                                }
                                .icon-otr3,
                                .icon-otr4{
                                    background-color: $Primary-color2;
                                }
                                .box-heading{
                                    flex: 1;
                                    color: $Secondary-color;
                                }
                            }
                            .box-desc{
                                color: $Secondary-color65;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    hosting-provider2 End Here
=====================================*/