

/*=====================================
        Overlay Start Here
=====================================*/

.overlay-main{
    .offcanvas-start{
        .offcanvas-header{
            align-items: center;
            border-bottom: 1px solid $Secondary-color15;
            padding: 16px 24px;
            .logo-otr{
                .logo{
                    width: 100px;
                    height: 70px;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .close-icon-otr{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background-color: $Secondary-color10;
                transition: .3s;
                &:hover{
                    background-color: $Secondary-color;
                    .icon{
                        color: $White-color;
                    }
                }
                .icon{
                    font-size: 24px;
                    color: $Secondary-color;
                    transition: .3s;
                }
            }
        }
        .offcanvas-body{
            padding: 24px;
            .menu-otr{
                margin-bottom: 36px;
                .menu-ul{
                    .accordion-item{
                        border: none;
                        border-radius: 0;
                        background-color: transparent;
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                        .accordion-header{
                            .accordion-button{
                                border: none;
                                box-shadow: none;
                                background-color: transparent;
                                border-radius: 0;
                                padding: 0;
                                margin: 0;
                                &::after{
                                    display: none;
                                }
                                .menu-p{
                                    color: $Secondary-color75;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-color;
                                    }
                                }
                            }
                        }
                        .accordion-collapse{
                            .accordion-body{
                                padding: 8px 0 0 24px;
                                .drop-otr{
                                    .drop-ul{
                                        .drop-li{
                                            &:not(:last-child){
                                                margin-bottom: 8px;
                                            }
                                            .drop-a{
                                                display: inline;
                                                color: $Secondary-color;
                                                transition: .3s;
                                                &:hover{
                                                    color: $Primary-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .menu-li{
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                        .menu-a{
                            display: inline;
                            color: $Secondary-color75;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
            .action{
                display: flex;
                width: 100%;
                .contact-btn{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    transition: .3s;
                    &:hover{
                        .icon{
                            color: $White-color;
                        }
                    }
                    .icon{
                        font-size: 24px;
                        color: $Primary-color;
                        margin-left: 12px;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

/*=====================================
        Overlay End Here
=====================================*/

/*=====================================
        Navbar/Hero Start Here
=====================================*/

.navbar-hero{
    position: relative;
    background-image: url("/assets/img/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $Gradient;
        z-index: -1;
    }
    .navbar-main{
        padding: 24px 0;
        border-bottom: 1px solid $Secondary-color15;
        .container-fluid{
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo-otr{
                    .logo{
                        width: 120px;
                        height: 80px;
                        object-fit: contain;
                    }
                }
                .menu-otr{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .menu-ul{
                        display: flex;
                        align-items: center;
                        gap: 0 50px;
                        .menu-li{
                            position: relative;
                            transition: .3s;
                            &:hover{
                                .drop-otr{
                                    top: 24px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            .menu-a{
                                color: $Secondary-color75;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .drop-otr{
                                position: absolute;
                                top: 40px;
                                left: -24px;
                                padding-top: 42px;
                                z-index: 9999;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                .drop-ul{
                                    width: 270px;
                                    background-color: $White-color;
                                    box-shadow: $Box-shadow;
                                    border-radius: 16px;
                                    .drop-li{
                                        position: relative;
                                        padding: 10px 28px;
                                        &:hover{
                                            .inr-drop-otr{
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                        &:not(:last-child){
                                            border-bottom: 1px solid $Secondary-color10;
                                        }
                                        .drop-a{
                                            display: inline;
                                            color: $Secondary-color;
                                            cursor: pointer;
                                            transition: .3s;
                                            &:hover{
                                                color: $Primary-color;
                                            }
                                        }
                                        .inr-drop-otr{
                                            position: absolute;
                                            top: 0;
                                            right: -210px;
                                            padding-top: 0px;
                                            z-index: 9999;
                                            opacity: 0;
                                            visibility: hidden;
                                            transition: 0.3s;
                                            .inr-drop-ul{
                                                width: 250px;
                                                background-color: $White-color;
                                                box-shadow: $Box-shadow;
                                                border-radius: 16px;
                                                .inr-drop-li{
                                                    padding: 10px 28px;
                                                    &:not(:last-child){
                                                        border-bottom: 1px solid $Secondary-color10;
                                                    }
                                                    .inr-drop-a{
                                                        display: inline;
                                                        color: $Secondary-color;
                                                        transition: .3s;
                                                        &:hover{
                                                            color: $Primary-color;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .action{
                    display: flex;
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .contact-btn{
                        display: flex;
                        align-items: center;
                        transition: .3s;
                        &:hover{
                            .icon{
                                color: $White-color;
                            }
                        }
                        .icon{
                            font-size: 24px;
                            color: $Primary-color;
                            margin-left: 12px;
                            transition: .3s;
                        }
                    }
                }
                .burger-icon-otr{
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background-color: $Secondary-color10;
                    transition: .3s;
                    @include breakpoint($secreen-max-md){
                        display: flex;
                    }
                    &:hover{
                        background-color: $Secondary-color;
                        .icon{
                            color: $White-color;
                        }
                    }
                    .icon{
                        font-size: 24px;
                        color: $Secondary-color;
                        transition: .3s;
                    }
                }
            }
        }
    }
    .hero-main{
        padding: 72px 0 96px 0;
        .container{
            .row-custom{
                align-items: center;
                justify-content: space-between;
                gap: 72px 0;
                @include breakpoint($secreen-xxs){
                    gap: 48px 0;
                }
                .col-content-otr{
                    .col-content-inr{
                        .tag{
                            &::before{
                                display: none;
                            }
                        }
                        .heading{
                            margin: 16px 0 24px 0;
                            color: $Secondary-color;
                            @include breakpoint($secreen-xs){
                                margin: 16px 0;
                            }
                            .heading-inr{
                                color: $Secondary-color50;
                            }
                        }
                        .desc{
                            color: $Secondary-color75;
                            margin-bottom: 40px;
                            @include breakpoint($secreen-xs){
                                margin-bottom: 24px;
                            }
                        }
                        .action{
                            display: flex;
                        }
                    }
                }
                .col-img-otr{
                    .col-img-inr{
                        .img{
                            width: 100%;
                            height: 650px;
                            object-fit: contain;
                            animation: transition 5s infinite linear;
                            @include breakpoint($secreen-max-md){
                                height: 500px;
                            }
                            @include breakpoint($secreen-xs){
                                height: 450px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Navbar/Hero End Here
=====================================*/

/*=====================================
        About Start Here
=====================================*/

.about-main{
    margin: 128px 0 72px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0 72px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 580px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .tag{
                        &::before{
                            display: none;
                        }
                    }
                    .heading{
                        margin: 8px 0 24px 0;
                        color: $Secondary-color;
                    }
                    .desc1{
                        color: $Secondary-color;
                    }
                    .desc2,
                    .desc3{
                        color: $Secondary-color75;
                    }
                    .desc{
                        &:not(:last-child){
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        About End Here
=====================================*/

/*=====================================
        Counter Start Here
=====================================*/

.counter-main{
    margin: 72px 0 128px 0;
    .container{
        .row-custom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-image: url("/assets/img/counter-bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: $Box-shadow;
            border-radius: 16px;
            padding: 56px 72px;
            gap: 30px;
            @include breakpoint($secreen-max-md){
                flex-wrap: wrap;
            }
            @include breakpoint($secreen-max-sm){
                padding: 24px;
            }
            .col-box-otr{
                @include breakpoint($secreen-max-md){
                    width: 46%;
                }
                @include breakpoint($secreen-xxs){
                    width: 100%;
                }
                .col-box-inr{
                    .heading{
                        color: $Secondary-color;
                        margin-bottom: 8px;
                    }
                    .heading2{
                        color: $Primary-color;
                    }
                    .heading3{
                        color: $Primary-color2;
                    }
                    .desc{
                        color: $Secondary-color75;
                    }
                }
            }
        }
    }
}

/*=====================================
        Counter End Here
=====================================*/

/*=====================================
        Services Start Here
=====================================*/

.services-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 72px 0 96px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .tag{

            }
            .heading{
                margin: 8px 0 24px 0;
                color: $Secondary-color;
            }
            .desc{
                color: $Secondary-color75;
            }
        }
        .row-custom{
            justify-content: center;
            gap: 24px 0;
            .col-box-otr{
                .col-box-inr{
                    background-color: $White-color;
                    padding: 36px 36px 28px 36px;
                    box-shadow: $Box-shadow;
                    border-radius: 16px;
                    height: 100%;
                    @include breakpoint($secreen-lg){
                        padding: 24px 24px 20px 24px;
                    }
                    @include breakpoint($secreen-max-sm){
                        padding: 24px 24px 20px 24px;
                    }
                    .icon-heading{
                        display: flex;
                        align-items: center;
                        gap: 0 30px;
                        margin-bottom: 24px;
                        @include breakpoint($secreen-lg){
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px 0;
                            margin-bottom: 16px;
                        }
                        @include breakpoint($secreen-max-sm){
                            margin-bottom: 16px;
                            gap: 0 16px;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px 0;
                            margin-bottom: 16px;
                        }
                        .icon-otr{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 72px;
                            height: 72px;
                            background-color: $Secondary-color;
                            border-radius: 16px;
                        }
                        .icon-otr2,
                        .icon-otr5{
                            background-color: $Primary-color;
                        }
                        .icon-otr3,
                        .icon-otr4{
                            background-color: $Primary-color2;
                        }
                        .heading{
                            flex: 1;
                            color: $Secondary-color;
                        }
                    }
                    .desc{
                        color: $Secondary-color65;
                    }
                }
            }
        }
        .action-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 72px;
            @include breakpoint($secreen-xs){
                margin-top: 48px;
            }
        }
    }
}

/*=====================================
        Services End Here
=====================================*/

/*=====================================
        Reviews Start Here
=====================================*/

.reviews-main{
    position: relative;
    background: $Gradient;
    padding: 128px 0;
    @include breakpoint($secreen-xs){
        padding: 72px 0 96px 0;
    }
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $Secondary-color;
        z-index: -1;
    }
    &::before{
        content: '';
        position: absolute;
        background-image: url("/assets/img/review-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .container{
        .wrapper{
            position: relative;
            text-align: center;
            margin-bottom: 72px;
            z-index: 2;
            .tag{

            }
            .heading{
                margin: 8px 0 24px 0;
                color: $White-color;
            }
            .desc{
                color: $White-color75;
            }
        }
        .row-custom{
            position: relative;
            justify-content: center;
            z-index: 2;
            .col-slider-otr{
                .col-slider-inr{
                    position: relative;
                    .swiper-wrapper{
                        height: auto;
                        .swiper-slide{
                            .col-box-otr{
                                background-color: $White-color;
                                padding: 36px;
                                border-radius: 16px;
                                @include breakpoint($secreen-xxs){
                                    padding: 24px 24px 36px 24px;
                                }
                                .comment{
                                    color: $Secondary-color;
                                    margin-bottom: 30px;
                                }
                                .profile-otr{
                                    display: flex;
                                    align-items: center;
                                    gap: 0 24px;
                                    @include breakpoint($secreen-xxs){
                                        flex-direction: column;
                                        align-items: flex-start;
                                        gap: 16px 0;
                                    }
                                    .img-otr{
                                        display: flex;
                                        .user{
                                            width: 60px;
                                            height: 60px;
                                            object-fit: cover;
                                            border-radius: 100%;
                                        }
                                    }
                                    .name{
                                        color: $Secondary-color;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                    .star-otr{
                                        .star-ul{
                                            display: flex;
                                            align-items: center;
                                            gap: 0 8px;
                                            .star-li{
                                                display: flex;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .arrow-otr{
                        display: flex;
                        align-items: center;
                        position: absolute;
                        bottom: 40px;
                        right: 36px;
                        gap: 0 16px;
                        @include breakpoint($secreen-xs){
                            display: none;
                        }
                        .icon-otr{
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            background-color: $Secondary-color10;
                            right: 0;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            transition: .3s;
                            &:hover{
                                background-color: $Primary-color;
                                .arrow-icon{
                                    color: $White-color;
                                }
                            }
                            &::after{
                                display: none;
                            }
                            .arrow-icon{
                                font-size: 24px;
                                color: $Secondary-color50;
                            }
                        }
                    }
                    .swiper-pagination{
                        display: none;
                        @include breakpoint($secreen-xs){
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .swiper-pagination-bullet{
                            width: 8px;
                            height: 8px;
                            background-color: $Secondary-color75;
                            border-radius: 100%;
                        }
                        .swiper-pagination-bullet-active{
                            background-color: $Secondary-color;
                        }
                    }
                }
            }
        }
        .wrapper2{
            display: flex;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 72px 150px;
            margin-top: 84px;
            @include breakpoint($secreen-lg){
                grid-gap: 48px 96px;
            }
            @include breakpoint($secreen-max-md){
                grid-gap: 56px;
            }
            @include breakpoint($secreen-xs){
                flex-wrap: wrap;
                justify-content: center;
            }
            .logo-otr{
                .logo-inr{
                    .logo{
                        width: 100%;
                        height: 72px;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
        }
    }
}

/*=====================================
        Reviews End Here
=====================================*/

/*=====================================
        Contact Start Here
=====================================*/

.contact-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 72px 0 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xs){
                gap: 48px 0;
            }
            .col-info-otr{
                .col-info-inr{
                    .tag{
                        &::before{
                            display: none;
                        }
                    }
                    .heading{
                        margin: 8px 0 24px 0;
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                        margin-bottom: 36px;
                    }
                    .info-ul{
                        .info-li{
                            &:not(:last-child){
                                margin-bottom: 16px;
                            }
                            .info-a{
                                display: inline-flex;
                                align-items: center;
                                gap: 0 16px;
                                transition: .3s;
                                &:hover{
                                    .icon-otr{
                                        background-color: $Secondary-color;
                                    }
                                    .info-text{
                                        color: $Primary-color;
                                    }
                                }
                                .icon-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 52px;
                                    height: 52px;
                                    background-color: $Primary-color;
                                    border-radius: 100%;
                                    transition: .3s;
                                }
                                .info-text{
                                    flex: 1;
                                    color: $Secondary-color;
                                    word-break: break-all;
                                    transition: .3s;
                                }
                            }
                        }
                    }
                }
            }
            .col-form-otr{
                .col-form-inr{
                    background-color: $White-color;
                    box-shadow: $Box-shadow;
                    padding: 24px 36px 36px 36px;
                    border-radius: 16px;
                    .input-otr{
                        &:not(:last-child){
                            margin-bottom: 18px;
                        }
                        .label{
                            font-size: 14px;
                            line-height: 30px;
                            margin-bottom: 8px;
                        }
                        .input{
                            width: 100%;
                        }
                        .textarea{
                            appearance: none;
                            resize: none;
                            height: 130px;
                        }
                        .form-select{
                            background-image: url("/assets/img/arrow-select.svg");
                            &:focus{
                                box-shadow: none;
                                background-image: url("/assets/img/arrow-select2.svg");
                            }
                        }
                    }
                    .action{
                        margin-top: 30px;
                        display: flex;
                        width: 100%;
                        .submit-btn{
                            width: 100%;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Contact End Here
=====================================*/

/*=====================================
        Footer Start Here
=====================================*/

.footer-main{
    padding: 24px 0;
    border-top: 1px solid $Secondary-color15;
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0 30px;
            @include breakpoint($secreen-max-sm){
                flex-direction: column;
                gap: 16px 0;
            }
            .logo-otr{
                .logo{
                    width: 120px;
                    height: 80px;
                    object-fit: contain;
                }
            }
            .copy-text{
                color: $Secondary-color75;
                text-align: center;
                .linkk{
                    display: inline;
                    color: $Secondary-color75;
                    transition: .3s;
                    &:hover{
                        color: $Secondary-color;
                    }
                }
            }
            .icon-ul{
                display: flex;
                align-items: center;
                gap: 0 16px;
                .icon-li{
                    .icon-a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 52px;
                        height: 52px;
                        background-color: $Primary-color;
                        border-radius: 100%;
                        transition: .3s;
                        &:hover{
                            background-color: $Secondary-color;
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        Footer End Here
=====================================*/