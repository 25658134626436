

/*=====================================
        SEO-building Start Here
=====================================*/

.SEO-building-main{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .row-custom{
            align-items: center;
            justify-content: space-between;
            gap: 72px 0;
            @include breakpoint($secreen-xxs){
                gap: 48px 0;
            }
            .col-img-otr{
                .col-img-inr{
                    .img{
                        width: 100%;
                        height: 580px;
                        object-fit: contain;
                        animation: transition 5s infinite linear;
                        @include breakpoint($secreen-max-md){
                            height: 500px;
                        }
                        @include breakpoint($secreen-xs){
                            height: 450px;
                        }
                    }
                }
            }
            .col-content-otr{
                .col-content-inr{
                    .heading{
                        color: $Secondary-color;
                    }
                    .desc{
                        color: $Secondary-color75;
                        margin: 24px 0 40px 0;
                    }
                    .row-custom-inr{
                        gap: 24px 0;
                        .col-box-otr{
                            .col-box-inr{
                                background-color: $White-color;
                                padding: 24px 24px 20px 24px;
                                box-shadow: $Box-shadow;
                                border-radius: 16px;
                                height: 100%;
                                @include breakpoint($secreen-max-sm){
                                    padding: 24px 24px 20px 24px;
                                }
                                .icon-heading{
                                    display: flex;
                                    align-items: flex-start;
                                    flex-direction: column;
                                    gap: 16px 0;
                                    margin-bottom: 16px;
                                    @include breakpoint($secreen-max-sm){
                                        margin-bottom: 16px;
                                        gap: 0 16px;
                                    }
                                    @include breakpoint($secreen-xxs){
                                        flex-direction: column;
                                        align-items: flex-start;
                                        gap: 16px 0;
                                        margin-bottom: 16px;
                                    }
                                    .icon-otr{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 72px;
                                        height: 72px;
                                        background-color: $Secondary-color;
                                        border-radius: 16px;
                                    }
                                    .icon-otr2,
                                    .icon-otr5{
                                        background-color: $Primary-color;
                                    }
                                    .icon-otr3,
                                    .icon-otr4{
                                        background-color: $Primary-color2;
                                    }
                                    .heading{
                                        flex: 1;
                                        color: $Secondary-color;
                                    }
                                }
                                .box-desc{
                                    color: $Secondary-color65;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
        SEO-building End Here
=====================================*/

/*=====================================
    SEO-building variation Start Here
=====================================*/

.seo-building-variation{
    margin: 128px 0;
    @include breakpoint($secreen-xs){
        margin: 96px 0;
    }
    .container{
        .wrapper{
            text-align: center;
            .heading{
                margin-bottom: 24px;
                color: $Secondary-color;
            }
            .desc{
                color: $Secondary-color75;
                br{
                    @include breakpoint($secreen-max-lg){
                        display: none;
                    }
                }
            }
        }
        .tabs-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 48px 0 72px 0;
            .nav-tabs{
                justify-content: center;
                border: none;
                gap: 16px;
                .nav-item{
                    .nav-link{
                        padding: 4px 16px;
                        border-radius: 8px;
                        background-color: $Secondary-color10;
                        color: $Primary-color;
                        border: none;
                        &:hover{
                            background-color: $Secondary-color;
                            color: $White-color;
                        }
                    }
                    .active{
                        background-color: $Secondary-color;
                        color: $White-color;
                    }
                }
            }
        }
        .tab-content{
            .row-custom{
                align-items: center;
                justify-content: space-between;
                gap: 72px 0;
                @include breakpoint($secreen-xxs){
                    gap: 48px 0;
                }
                .col-img-otr{
                    .col-img-inr{
                        .img{
                            width: 100%;
                            height: 450px;
                            object-fit: contain;
                            animation: transition 5s infinite linear;
                            @include breakpoint($secreen-max-md){
                                height: 350px;
                            }
                            @include breakpoint($secreen-xs){
                                height: 300px;
                            }
                            @include breakpoint($secreen-xxs){
                                height: 250px;
                            }
                        }
                    }
                }
                .col-content-otr{
                    .col-content-inr{
                        .heading{
                            color: $Secondary-color;
                        }
                        .desc{
                            color: $Secondary-color75;
                            margin: 24px 0 16px 0;
                        }
                        .check-otr{
                            color: $Secondary-color;
                            &:not(:last-child){
                                margin-bottom: 12px;
                            }
                            .check{
                                margin-right: 16px;
                            }
                        }
                        .desc-ul{
                            margin-bottom: 24px;
                            padding-left: 20px;
                            .desc-li{
                                color: $Secondary-color75;
                                list-style: auto;
                                &:not(:last-child){
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
    SEO-building variation End Here
=====================================*/